@import "../functions/functions-fonts";

footer {
  color: var(--white);
  background: var(--black);
}

.rS a {
  width: pxToRem(31px);
  height: pxToRem(31px);
}

.rS .fb {
  background: #4267b2;
}

.rS .tw {
  background: var(--white);
}

.rS .yt {
  background: #f00;
}

.rS .ct {
  background: var(--secondary);
}

.rS .facebook, .rS .twitter, .rS .contactUs, .rS .youTube {
  width: 47px;
  height: 47px;
  margin-left: 0;
  transition: background 0.2s linear;
  -webkit-transition: background 0.2s linear;
  -moz-transition: background 0.2s linear;
  -ms-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
}

.text-footer {
  text-align: center;
  justify-content: center;
  align-items: center;

  .copyright {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}

.container-notice {
  font-size: 0.875rem;
  text-align: center;

  .container-two-elements {
    display: flex;
    justify-content: center;
  }
}

.container-notice a:not(:last-child) {
  border-right: pxToRem(1px) solid var(--white);
}

.container-notice > a:first-child {
  border-right: none;
}

.container-notice a, .container-social a {
  color: var(--white);
}

.container-notice a:hover {
  color: var(--secondary);
}

.container-social a {
  overflow: hidden;
  position: relative;
  border-radius: pxToRem(4px);
  display: inline-block;
}

.container-social a i {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.7rem;
  position: absolute;
  transition: all 0.3s ease-out;
}

.container-social a:hover i {
  opacity: 0.4;
}

.container-social .fb i {
  top: pxToRem(7px);
  left: pxToRem(5px);
  font-size: 1.4rem;
}

.container-social .tw i {
  top: pxToRem(4px);
  left: pxToRem(4px);
  font-size: 1.5rem;
  color: var(--black);
}

.container-social .yt i {
  font-size: 1.2rem;
  top: pxToRem(6px);
  left: pxToRem(5px);
}

.container-social .ct i {
  font-size: 1.2rem;
  top: pxToRem(6px);
  left: pxToRem(6px);
}

.container-social-app a {
  padding: 0.375rem 0;
  text-align: center;
  width: 40px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f8f9fa;
}

.container-social-app a:hover {
  color: var(--secondary);
}

.container-social-app i {
  font-size: 1.5rem;
  display: inline-block;
  padding-top: 0.2rem;
}

footer .copyright {
  color: #b3b3b3bf;
  font-size: pxToRem(12px);
}

body[dir='rtl'] {
  .container-notice a:first-child {
    border-right: none;
  }

  .container-notice a:not(:last-child) {
    border-left: pxToRem(1px) solid var(--white);
  }

  .container-notice > a:first-child {
    border-left: none;
  }

  .container-social .fb i {
    right: pxToRem(5px);
  }

  .container-social .yt i {
    right: pxToRem(5px);
  }

  @media (max-width: 576px) {
    .container-notice {
      .container-two-elements {
        .cgu-element {
          border-left: pxToRem(1px) solid var(--white);
          border-right: none;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .container-notice a:not(:last-child) {
      border-left: pxToRem(1px) solid var(--white);
    }
  }
}

@media (max-width: 576px) {
  .container-notice {
    display: flex;
    flex-direction: column;

    .container-two-elements {
      .cgu-element {
        border-right: pxToRem(1px) solid var(--white);
      }
    }
  }
}

@media (min-width: 768px) {

  footer .copyright {
    color: #b3b3b3bf;
    font-size: pxToRem(14px);
    margin-inline-start: pxToRem(6px);
  }

  .text-footer {
    text-align: start;
    justify-content: start;
    align-items: start;
    padding-inline-start: pxToRem(5px);

    &.footer-link-container {
      padding-inline-start: pxToRem(10px);
    }

    .copyright {
      text-align: start;
      justify-content: start;
      align-items: start;
      margin-inline-start: 10px;
    }
  }

  .container-notice {
    .container-two-elements {
      justify-content: start;
    }
  }

  .container-notice a:first-child {
    border-right: pxToRem(1px) solid var(--white);
  }
}

@media (min-width: 1200px) {

  .container-notice {
    display: flex;
    flex-direction: row;
  }
  .text-footer {
    &.footer-link-container {
      padding-inline-start: 0;
    }
  }
}