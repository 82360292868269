.layout-menu .container-account .dropdown-menu.post-connect {
  width: 265px;
  right: 48px;
  padding: 1rem;
}

.layout-menu .container-account .dropdown-menu.post-connect a {
  font-size: 1rem;
  color: var(--white);
  padding-inline-start: 0.5rem;
  text-align: start;
  margin-bottom: 0.5rem;
}

.layout-menu .container-account .dropdown-menu.post-connect i {
  margin-inline-end: 0.5rem;
  font-size: 1.2rem;
  color: var(--secondary);
  vertical-align: middle;
}

body[dir="RTL"] .layout-menu .container-account .dropdown-menu.post-connect {
  left: 32px;
  right: initial;
}

body[dir="RTL"] .layout-menu .navbar-nav .dropdown-menu:before, body[dir="RTL"] .layout-menu .container-account .dropdown-menu:before {
  left: 50%;
}

@media (max-width: 991.98px) {
  .layout-menu .container-account .dropdown-menu.post-connect .title {
    color: var(--white);
    font-weight: bold;
  }

  body[dir="RTL"] .layout-menu .container-account .dropdown-menu.post-connect {
    right: initial;
    left: 0;
  }

  .layout-menu .container-account .dropdown-menu.post-connect {
    width: 100%;
    right: 0;
  }
}
