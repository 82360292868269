@use "../mixins/mixins-cards" as *;
@use '../functions/functions-fonts' as *;


.products-banner {
  padding-top: 3rem;
  padding-bottom: 5.4rem;

  .banner-img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(var(--white-rgb), 0.69);
    display: flex;
    justify-content: center;
    padding: 0.625rem 0;
  }

  .badge-network {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    color: var(--white);
    font-weight: bold;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline-end: 0.375rem;
    font-size: 0.65rem;

    &.network-2g {
      background: #0B5BA0;
    }

    &.network-3g {
      background: #9ECA25;
    }

    &.network-4g {
      background: #FF620D;
    }

    &.network-4g-plus {
      background: #B4131D;
    }

    &.network-5g {
      background: #8520F2;
    }
  }
}

.products-banner h2 {
  text-align: center;
  font-size: 1.375rem;
  margin-bottom: 3.25rem;
}

.products-banner {
  .card {
    @include card(var(--dark), var(--light-2), true);
    margin-bottom: 2rem;
  }

  &.bg-light-blue {
    .card {
      background: var(--light);
    }
  }
}

.products-banner .card:hover .card-img-top {
  img {
    transform: scale(1.1);
  }

  .banner-img {
    img {
      transform: initial;
    }
  }
}

.products-banner .card .award-img img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.products-banner .card:hover .award-img img {
  transform: scale(1.1);
}

.products-banner .card .award-img img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.products-banner .card:hover .award-img img {
  transform: scale(1.1);
}

.products-banner .card .award-img img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.products-banner .card:hover .award-img img {
  transform: scale(1.1);
}

.products-banner .card .award-img img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.products-banner .card:hover .award-img img {
  transform: scale(1.1);
}

.products-banner .card:hover .card-img-top.host-server img {
  transform: scale(1.2);
}

.products-banner .card-img-top {
  border-top-left-radius: calc(0.625rem - 1px);
  border-top-right-radius: calc(0.625rem - 1px);
  text-align: center;
  height: 245px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.products-banner .card-img-top img {
  height: 245px;
  width: auto;
  transition: transform 0.2s linear;
}

.products-banner .card-img-top.coverage-map img {
  height: 280px;
}

.products-banner .card-img-top.fleet img {
  height: 255px;
  width: 100%;
}

.products-banner .card-img-top.sdk {
  background-color: #F2F7FE;

  img {
    height: 255px;
    padding-top: 50px;
  }
}

.products-banner .card-img-top.probes img {
  height: 255px;
  width: 100%;
}

.products-banner .card-img-top.analytics img {
  height: 255px;
  width: 100%;
}

.products-banner .card-img-top.get-award-img img {
  height: 255px;
  width: auto;
  scale: 1.1;
}

.products-banner .card-img-top.custom-web-app img {
  height: 255px;
  width: 100%;
  background-color: #c6deff;
}

.products-banner .card-img-top.custom-mobile-app img {
  background-color: #c6deff;
  height: 255px;
  width: 100%;
}

.products-banner .card-img-top.custom-web-maps img {
  height: 255px;
  width: 100%;
  background-color: #A09DD9;
}


.products-banner .card-img-top.desktop-app img {
  height: 210px;
}

.products-banner .card-img-top.speed-test {
  background: linear-gradient(126deg, #afe2fe 0%, #dcf3fb 100%, #dcf6ff 100%);
}

.products-banner .card-img-top.mobile-app {
  background: linear-gradient(126deg, #4278c3 0%, #00192c 100%);
}

.products-banner .card-img-top.mobile-app img {
  height: 240px;
  transform: translateY(53px);
  scale: 1.1;
}

.products-banner .card:hover .card-img-top.mobile-app img {
  transform: translateY(64px) scale(1.1);
}

.products-banner .card-img-top.speed-test img {
  height: 220px;
  transform: translateY(10px);
}

.products-banner .card-img-top.desktop-app {
  background: transparent linear-gradient(234deg, #20d7ab 0%, #1b71d5 100%);
}

.products-banner .card-body {
  text-align: center;
}

.products-banner .card-body h3 {
  font-size: 1.125rem;
  color: var(--dark);
}

.products-banner .card-body p {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: var(--gray-dark-2);
  font-size: 0.875rem;
}

.products-banner .card-img-top.host-server img {
  transform-origin: center;
  transform: scale(1.03);
}

.products-banner .card-img-top.speed-map .banner-img img {
  height: auto;
}

.products-banner .card-link {
  width: 100%;
}

.products-banner .card-body {
  padding: 0;
}

.products-banner .card-body .card-link {
  padding: 1.25rem;
  display: inline-block;
}

.products-banner .card-img-top.map-5g img {
  height: 295px;
}


@media (min-width: 360px) {

  .products-banner .card {
    max-width: 360px;
  }

  .products-banner .card-img-top.desktop-app img {
    height: 245px;
  }
}