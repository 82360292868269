
.formUser form button.close, .formUser form button.close:not(:disabled):not(.disabled):focus, .formUser form button.close:not(:disabled):not(.disabled):hover {
  height: 30px;
  width: 30px;
  opacity: 1;
}

.formUser form button.close:hover {
  background-color: var(--black);
  opacity: 1;
}

.formUser {
  width: 100%;
  min-width: 240px;
}

.formUser * {
  font-weight: 300;
}

.formUser button {
  height: 40px;
  border: none;
  background-color: var(--primary);
}

.formUser form button, .formUser .container-create-account .btn {
  border: 1px solid var(--secondary);
  border-radius: 6rem;
  background: none;
  opacity: 1;
  color: var(--secondary);
  font-size: 0.875rem;
  padding: 0.375rem 1.5rem;
  font-weight: bold;
}


.formUser form button.focus, .formUser form button:focus {
  outline: 0;
  box-shadow: none;
}

.formUser .form-control {
  background: var(--gray-dark);
  border-radius: 0.5rem;
  color: var(--gray-medium);
  height: calc(1.5em + 1.2rem + 2px);
}

.formUser .form-control:focus, .formUser .form-control:visited {
  text-decoration: none !important;
  border: 1px solid var(--gray-dark);
  box-shadow: none !important;
}

.formUser .form-control.UserSub {
  background-color: var(--white);
  border: 1px solid var(--gray-medium);
  color: var(--gray-medium);
  font-size: 14px;
}

.custom-select:focus {
  border: 1px solid var(--primary);
}

.formUser .custom-select {
  font-size: 14px;
  opacity: 0.8;
}

.formUser .custom-select:focus option {
  color: var(--white);
  font-weight: 300;
}


.formUser form button:hover, .formUser .container-create-account .btn:hover {
  background-color: var(--secondary);
  font-weight: bolder;
  color: var(--black);
}

.formUser .form-control, .form-control.is-valid, .was-validated .form-control:valid {
  background-color: var(--gray-dark);
  border: 1px solid var(--gray-dark);
  color: var(--white);
  background-image: none;
}

.formUser .form-control.is-invalid, .formUser .was-validated .form-control:invalid, .formUser .login-menu-input.is-invalid:focus {
  border-color: var(--variant-6);
  background-image: none;
}

.toolUser .modal-body p, .modal-content, .modal-header, .modal-title, .modal-body, modal-footer, .modal-dialog button {
  color: var(--dark);
}

.modal-dialog {
  .msg-success-create-account {
    button {
      color: var(--tertiary);
    }
  }
}

.modal-body {
  background: var(--light-3);
  border-radius: 0 0 pxToRem(10px) pxToRem(10px);
}

.formUser .login-menu-input:focus {
  background: white;
  color: var(--gray-medium);
}

.formUser .login-menu-input::placeholder {
  color: var(--gray-medium);
}

.formUser .container-btn-help-account button {
  background: none;
  font-size: 0.75rem;
  color: var(--gray-medium);
  height: initial;
  margin: 0 auto;
}

.formUser .container-btn-help-account button:hover {
  color: var(--secondary);
}

.formUser .container-create-account p {
  color: var(--white);
  font-size: 1rem;
  text-align: start;
}

.formUser .container-pre-connect {
  width: 100%;
}

.formUser .invalid-feedback {
  color: var(--variant-6);
  position: relative;
}

#login-form .icon-invalid {
  display: none;
  position: absolute;
  top: 12px;
  right: 9px;
  color: var(--variant-6);
  font-size: 0.8rem;
}

#login-form .form-control.is-invalid ~ .icon-invalid {
  display: inline-block;
}

.formUser form[name='login-form'] {
  position: relative;
}

.container-error-submit, .container-loader {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: var(--black);
  color: var(--variant-6);
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  min-height: 100%;
  padding: 1rem 0.5rem;
  transition: 0.3s all ease-in;
}

.container-error-submit > div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.container-error-submit .btn {
  background: none;
  color: var(--gray-medium);
  padding: 0;
  height: initial;
  font-size: 0.85rem;
}

.container-error-submit .btn:hover {
  background: none;
  height: initial;
  color: var(--gray-medium);
  font-weight: initial;
}

.layout-menu .container-account .btnTool .container-error-submit span.error-submit {
  font-size: 0.9rem;
  display: inline-block;
  text-align: center;
}

.container-account .loader-spinner {
  display: none;
  color: var(--secondary);
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: middle;
}

#login-form .loader-spinner {
  display: none;
  color: var(--variant-6);
  width: 3rem;
  height: 3rem;
}

@media (max-width: 1199.98px) {
  .layout-menu .btn-connect-status .text-label-login {
    max-width: 100px;
  }
}

@media (max-width: 991.98px) {
  .layout-menu .container-account .dropdown-menu .title, .formUser .container-create-account p {
    text-align: center;
  }

  .layout-menu #login-form {
    width: 60%;
    margin: 0 auto;
  }
}

@media (max-width: 767.98px) {
  .layout-menu .container-account .dropdown-menu .title, .formUser .container-create-account p {
    text-align: start;
  }
  .layout-menu #login-form {
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  .formUser .container-pre-connect {
    width: 100%;
  }
}