@use '../functions/functions-fonts' as *;
@use "../mixins/mixins-cards" as *;

.modal-desktop-app-versions {
  .container-title {
    justify-content: center;
    display: flex;
    margin-bottom: pxToRem(12px);
  }

  .container-logo {
    background: var(--variant-10);
    color: var(--black);
    width: 62px;
    height: 62px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.75rem;
  }

  .modal-body {
    background: var(--light-3);
    border-radius: 0 0 pxToRem(10px) pxToRem(10px);
  }

  .modal-header {
    background: linear-gradient(266deg, var(--variant-21-2) 0, var(--variant-22-2) 100%);

    .npicn-cross-close {
      color: var(--light);
    }
  }

  .modal-title {
    font-size: pxToRem(18px);
    margin-bottom: pxToRem(14px);
    color: var(--dark);
  }

  .modal-header-title {
    margin-bottom: pxToRem(20px);
    text-align: center;

    p {
      font-size: pxToRem(12px);
      color: var(--dark);
    }
  }

  .container-version {
    .card-os {
      @include card(var(--dark), var(--light-2), true);
      padding: pxToRem(14px) pxToRem(13px);
      margin-bottom: 1rem;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0 1px 6px #0000002B;

      .version-name {
        font-size: pxToRem(13px);
        font-family: var(--font-title), sans-serif;
        font-weight: bold;
        color: var(--dark);
      }

      .container-version {
        padding-top: 0.875rem;
      }

      .btn-download {
        margin-top: 0;
      }
    }

    .card-title {
      p {
        text-align: start;
        font-size: pxToRem(10px);
        color: var(--gray-medium);
      }
    }
  }

}