@mixin input($bgColor: none, $color: var(--primary), $bgHover: var(--primary), $colorHover: var(--light)) {
  background: $bgColor;
  border: 1px solid $color;
  border-radius: 0.5rem;
  color: $color;
  font-size: 1rem;
  text-align: start;
  &:hover, &:focus {
    color: $colorHover;
    background-color: $bgHover;
    border-color: $colorHover;
  }
  &:focus {
    box-shadow: none;
  }
}