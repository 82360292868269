@import "../mixins/mixins";
@import "bootstrap4.3.1/scss/spinners";
@import "bootstrap4.3.1/scss/modal";
@import "bootstrap4.3.1/scss/toasts";
@import "bootstrap4.3.1/scss/tables";
@import "bootstrap4.3.1/scss/pagination";
@import "bootstrap4.3.1/scss/list-group";
@import "bootstrap4.3.1/scss/button-group";
@import "bootstrap4.3.1/scss/transitions";
@import '~flag-icons/sass/flag-icons.scss';
@import "../functions/functions-fonts";
@import "../mixins/mixins-responsive";
@import "../components-style/inputs";
@import "../components-style/pre-connect";
@import "../components-style/post-connect";
@import "../components-style/footer";
@import "../components-style/banner-products";
@import "../components-style/modal-applications-versions";


p {
  margin-bottom: 0;
}

a {
  color: var(--dark);
}

a:hover {
  text-decoration: none;
  color: inherit;
}

a.is-disabled {
  color: var(--gray-medium);
  font-style: italic;
  cursor: not-allowed;
  pointer-events: none;
}

.text-underline {
  text-decoration: underline;
}

.container-header-product .container-content-header h2 {
  font-size: 1.375rem;
  margin-bottom: 1.181rem;
}

.grecaptcha-badge {
  visibility: hidden;
}

.btn-contact-product {
  width: 50%;
  margin: pxToRem(48px) auto 0;
  max-width: 170px;

  i {
    margin-inline-end: 0.25rem;
    margin-inline-start: 0;
    display: flex;
    align-items: center;
  }
}

.dropdown-item, .dropdown-item:hover, .dropdown-item:active {
  color: var(--variant-10);
  background: none;
  font-size: 1rem;
  font-weight: normal;
}

a.nav-link {
  text-decoration: none;
  z-index: 1;
}

.bg-banner {
  background: rgba(var(--dark-rgb), 0.2);
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  box-shadow: 0 pxToRem(4px) pxToRem(30px) rgba(var(--black-rgb), 0.1);
  color: var(--light);
  font-size: 1.875rem;
  padding: 1.5rem 0 1.25rem;
}

.banner-title {
  h1 {
    margin-bottom: 0.25rem;
  }
}

.marker-app {
  iframe#feedback-button {
    z-index: 400 !important;
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.spinner-border {
  color: var(--primary);
}

.container-table {
  position: relative;

  &:before, &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 15%;
  }

  &.scroll-table-left {
    &:before {
      left: 0;
      background-image: linear-gradient(to left, rgba(255, 0, 0, 0), rgb(var(--light-rgb)))
    }
  }

  &.scroll-table-right {
    &:after {
      right: 0;
      background-image: linear-gradient(to right, rgba(255, 0, 0, 0), rgb(var(--light-rgb)));
    }
  }
}

.modal-auth {
  .modal-title {
    font-size: pxToRem(18px);
    text-align: center;
    margin-bottom: pxToRem(34px);
  }

  .input-validation {
    text-align: start;
  }

  label {
    font-weight: bold;
    text-align: start;
  }

  input {
    border-color: var(--gray-medium);

    &:focus {
      border-color: var(--primary);
    }
  }

  .container-select .ts-wrapper .ts-control {
    border: 1px solid var(--gray-medium);

    &:focus {
      border: 1px solid var(--primary);
    }
  }

  .input-group > .input-group-append > .input-group-text {
    border-color: var(--gray-medium);
  }

  input:focus + .input-group-append .input-group-text {
    border-color: var(--primary);
  }

  .container-select .name, .container-select .name-rtl {
    color: var(--dark);
  }

  .container-select .ts-wrapper .ts-control:after {
    border-top-color: var(--dark);
    border-left-color: var(--dark);
  }

  .form-control.is-invalid ~ .input-group-append .input-group-text, .tomselected.is-invalid ~ .ts-wrapper .ts-control {
    border-color: var(--alert);
  }

  .form-control.was-validated ~ .input-group-append .input-group-text {
    border-color: var(--secondary);
  }

  .form-control.was-validated:focus + .input-group-append .input-group-text {
    border-color: var(--primary);
  }

  &.modal-update-user-mail {
    .form-structure {
      margin-bottom: pxToRem(5px);
    }

    .legend {
      font-size: pxToRem(14px);
      text-align: center;
      margin-bottom: pxToRem(13px);
    }
  }

  button[type='submit'] {
    color: var(--primary);
    background: none;

    &:disabled {
      color: var(--gray-medium);
      background: none;
    }

    &:hover, &:focus {
      color: var(--light);
      background: var(--primary);
    }
  }

  .captcha-text {
    margin-top: pxToRem(17px);
    color: var(--gray-medium);

    a {
      color: var(--gray-medium);
      text-decoration: underline;
    }
  }

  .btn-tooltip {
    color: var(--gray-medium);
  }

}

.modal-global-error {
  .modal-header {
    min-height: 2rem;
    background: rgba(0, 0, 0, 0) linear-gradient(266deg, #FF6464 0%, #C11B1B 100%) 0 0 no-repeat padding-box;

    .close {
      display: block;
      color: var(--white);
    }
  }


  .modal-error-btn {
    margin-bottom: pxToRem(56px);
    padding-top: pxToRem(20px);

    .btn {
      color: var(--primary);

      &:hover, &:focus {
        color: var(--white);
      }
    }
  }

  .modal-dialog {
    max-width: 510px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline-start: pxToRem(24px);
  margin-inline-end: pxToRem(24px);

  .circle {
    background-color: var(--variant-16);
    margin-top: pxToRem(56px);
    overflow: hidden;
    border-radius: 50%;
    width: pxToRem(81px);
    height: pxToRem(81px);
    padding: pxToRem(24px);
    text-align: center;
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: pxToRem(36px);
  }

  .icon-warning {
    color: var(--alert);
    font-size: pxToRem(40px);
    position: absolute;
  }

  #modalText {
    font-size: pxToRem(18px);
    font-family: var(--font-title), sans-serif;
    font-weight: bold;
    color: var(--dark);
    text-align: center;
    margin-bottom: pxToRem(10px);
  }
}

.line-bottom:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 5rem;
  height: 2px;
  background: linear-gradient(90deg, #0c66aa, #94c11c);
}

.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: var(--variant-15);
}

body[dir='RTL'] {
  .line-bottom:after {
    left: initial;
    right: 0;
  }

  .container-color-mode {
    #toggleColorMode {
      .circle-toggle {
        &.toggle-light {
          right: initial;
          left: 0.4rem;
          animation: .4s ease forwards slideSwitchLTR;
        }

        &.toggle-dark {
          right: 0.4rem;
          left: initial;
          animation: .4s ease forwards slideSwitchRTL;
        }
      }
    }
  }
}

@media (min-width: 576px) {
  main {
    min-height: calc(100vh - 272px);
  }
}

@media (min-width: 768px) {
  .container-md {
    max-width: 80%;
    margin: auto;
  }

  .container-header-product .container-content-header h2 {
    margin-bottom: 1.25rem;
  }

  main {
    min-height: calc(100vh - 142px);
  }
}

@media (min-width: 992px) {
  .container-color-mode {
    margin-inline-end: 2.5rem;
  }
}

@media (min-width: 1200px) {
  .container-md {
    max-width: 1200px;
  }

  .container-header-product .container-content-header h2 {
    text-align: start;
    padding-inline-start: 0.7rem;
  }

  main {
    min-height: calc(100vh - 121px);
  }

  .modal-auth {
    .modal-title {
      font-size: pxToRem(26px);
    }

    &.modal-auth.modal-update-user-mail {
      .legend {
        font-size: 1rem;
      }
    }
  }

  .btn-contact-product {
    margin: pxToRem(76px) auto 0;
  }

  .container-color-mode {
    margin-inline-end: 2.35rem;
  }
}

.table.AwardPays {
  font-size: 1em !important;
}


.container-docs .award-document-link i {
  font-size: 1.4em;
}

.paginationAward {
  display: flex;
  justify-content: center;
}

.paginationAward .page-link {
  background: none;
  border: 1px solid #fff;
  color: #767676;
  display: inline-block;
  margin: 0;
  padding: 0.25rem 0.7rem;
}

.paginationAward .next, .paginationAward .prev {
  display: flex;
}

.paginationAward.pagination, .paginationAward.pagination-sm .page-item:first-child .page-link, .paginationAward.pagination-sm .page-item:last-child .page-link {
  border-radius: 0 !important;
  font-weight: 700;
}

.paginationAward .next .page-link, .paginationAward .prev .page-link {
  font-size: 0;
  height: 100%;
  position: relative;
}

.page-item.disabled .page-link {
  opacity: 0.6;
}

.paginationAward .dotted .page-link, .paginationAward .next .page-link, .paginationAward .prev .page-link {
  border: none;
}

.awardsTable .pdfCell a {
  display: inline-block;
  padding: 0 pxToRem(5px);
  text-align: center;
  text-transform: uppercase;
}

.paginationAward .next .page-link:before, .paginationAward .prev .page-link:before {
  display: inline-block;
  font-size: 0.9rem;
  position: absolute;
  top: 50%;
}

.awards-table tr > .title-wrapper p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table.award-archives {
  border-bottom: pxToRem(1px) solid #f7ecdf;
  font-size: 0.9em;
}

.table.AwardPays .title-link {
  display: flex;
  align-items: center;
  height: 100%;
}

.award-archives .container-docs {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

@media (max-width: 767px) {
  .page-item.active, .page-item.dotted {
    display: list-item;
  }
  .page-item {
    display: none;
  }
}